// =============================================================================
// Desciptions
// =============================================================================

.description-horizontal {
  display: block;
  margin: 0;
  font-size: 0;

  button {
    position: absolute;
    right: 15px;
    text-align: right;
    .action {
      display: inline-block;
      margin-left: 15px;
      vertical-align: middle;
    }
  }

  dt,
  dd {
    @include truncate();
    display: inline-block;
    font-size: $base-font-size;
    vertical-align: middle;
    width: 35%;
    margin-top: 10px;
    &:first-of-type {
      margin-top: 0;
    }
  }

  dt {
    font-weight: 400;
  }

  dd {
    width: 60%;
    margin-left: 5%;
    font-weight: 500;

    &.action-button {
      width: 50%;
    }

    &.wrap {
      vertical-align: top;
      white-space: pre-wrap;
    }

    &.multiline {
      width: 100%;
      margin-left: 0;
      white-space: pre-wrap;
    }
  }

  &.dt-long {
    dt {
      width: 60%;
    }
    dd {
      width: 35%;
    }
  }
}
