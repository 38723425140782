// =============================================================================
// Subnav
// =============================================================================

// Component
// =============================================================================

$gray-slightlydark: darken($gray, 10);

.subnav {
  grid-area: 2 / 2 / 3 / 3;
  background: $white;
}

// Descendants
// =============================================================================

// Subnav main is a heading with title, buttons and/or descriptions.

.subnav-main {
  position: relative;
  display: block;
  padding: $main-padding;
  border-bottom: 1px solid $base-border-color;
  h2 {
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    vertical-align: middle;
    small {
      display: inline-block;
      width: 12px;
      height: 12px;
      margin-top: -2px;
      margin-left: 5px;
      color: $gray;
      vertical-align: middle;
    }
  }
  > p {
    display: block;
    max-width: 600px;
  }
  .button {
    position: absolute;
    top: 50%;
    right: $main-padding;
    margin-top: -$main-padding;
  }
}

// Contains extra information about the page or data. Line underneath the subnav
// title.

.subnav-content {
  display: inline-block;
  vertical-align: middle;
  h2 {
    color: $gray-darker;
  }
  p {
    display: block;
    margin-top: 2px;
    color: $gray-slightlydark;
    a {
      font-weight: 500;
      vertical-align: middle;
      i {
        display: inline-block;
        margin-top: -3px;
        font-size: 20px;
        vertical-align: middle;
      }
    }
    img {
      display: inline-block;
      height: 11px;
      vertical-align: middle;
    }
    small {
      display: inline-block;
      margin: 0 5px;
      font-weight: 600;
      color: $gray;
      vertical-align: middle;
    }
    strong {
      display: inline-block;
      font-weight: 500;
      vertical-align: middle;
    }
    .pill {
      vertical-align: middle;
      margin-right: 5px;
    }
    .pill:last-of-type {
      margin-right: 0px;
    }
  }
}

// Subnav links wrapper contains a subnav and makes it horizontal scrollable

.subnav-links-wrapper {
  width: 100%;
  height: $subnav-height;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}

// Subnav links contains all the sub links for a given page.

.subnav-links {
  position: relative;
  display: flex;
  min-width: 100%;
  height: $subnav-height;
  padding: 10px $main-padding 0;
  margin: 0;
  list-style: none;
  border-bottom: 1px solid $base-border-color;
  overflow-x: auto;
  &:empty {
    height: 0;
    padding: 0;
  }
  // Prevent margin-right on last li from "falling off"
  &::after {
    content: '';
    display: block;
    pointer-events: none;
    width: 1px;
    flex-shrink: 0;
  }
  li {
    display: inline-block;
    margin-right: 20px;
    white-space: nowrap;

    a,
    &.subnav-button {
      display: inline-block;
      margin: 0;
      padding-bottom: 10px;
      font-size: 13px;
      font-weight: 500;
      color: $gray-slightlydark;
      cursor: pointer;
      border-bottom: 2px solid transparent;
      transition: all $base-transition-speed linear;
      i {
        display: inline-block;
        margin-top: -1.5px;
        font-size: 20px;
        vertical-align: middle;
      }
      &:hover {
        color: $gray-dark;
      }
      &:active {
        color: $brand-primary;
      }
      &.is-active {
        color: $gray-dark;
        border-color: $brand-primary;
      }
    }
    &:first-child i {
      margin-right: 0;
      margin-left: -5px;
    }
  }
}

.subnav-links-left-arrow,
.subnav-links-right-arrow {
  position: absolute;
  min-width: auto;
  top: 0;
  z-index: 20;
  background: $white;
  padding-left: $main-padding / 2;
  padding-right: $main-padding / 2;
}

.subnav-links-left-arrow {
  left: 0;
}

.subnav-links-right-arrow {
  right: 0;
}

.subnav-links::-webkit-scrollbar {
  display: none;
}

// Additional information about the current subnavigation page

.subnav-details {
  pointer-events: none;
  position: relative;
  display: block;
  min-height: $subnav-height;
  padding: 10px $main-padding;
  margin: 0;
  list-style: none;
  border-bottom: 1px solid $base-border-color;

  li {
    display: inline-block;
    margin-right: 20px;
    font-size: 13px;
    font-weight: 500;
    color: $gray;
    strong {
      padding-left: 5px;
      color: $gray-dark;
    }
    i {
      display: inline-block;
      font-size: 20px;
      line-height: 0.9;
      vertical-align: middle;
    }
  }

  .loader-placeholder-wrapper {
    width: 50%;
  }
}

// Sessions
// =============================================================================

.subnav-session-details {
  @include clearfix();

  position: relative;
  display: block;
  min-height: $subnav-height;
  padding: 8px $main-padding;
  border-bottom: 1px solid $base-border-color;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    display: inline-block;
    vertical-align: middle;
    font-size: 13px;
    font-weight: 500;
    color: $gray-slightlydark;

    button {
      padding: 8px 14px;
      margin-top: -4px;
    }

    strong {
      padding-left: 5px;
      color: $gray-dark;
    }

    > i {
      display: inline-block;
      font-size: 20px;
      line-height: 0.9;
      vertical-align: middle;
      color: $gray-dark;
    }
  }

  ul.left {
    @include float-left;

    li {
      margin-right: $main-padding / 2;
    }
  }

  ul.right {
    @include float-right;

    li {
      margin-left: $main-padding / 2;
    }
  }
}

// Planning
// =============================================================================

.subnav-date {
  float: left;
  width: 425px;
  height: 60px;
  padding: $main-padding $main-padding $main-padding 0;
  text-align: center;
  border-right: 1px solid $base-border-color;
  h4 {
    display: inline-block;
    padding: 0 30px;
    color: $gray-darker;
    text-align: center;
    em {
      display: block;
      font-size: 11px;
      color: $gray;
    }
  }
  i {
    margin-top: 5px;
    font-size: 20px;
    color: $gray;
    cursor: pointer;
    transition: color $base-transition-speed linear;
    &:hover {
      color: $gray-dark;
    }
    &:first-child {
      float: left;
    }
    &:last-child {
      float: right;
    }
    &.is-disabled {
      color: $gray-lighter;
      cursor: default;
    }
  }
}

.subnav-controls {
  display: block;
  height: 62px;
  padding: 0 $main-padding;
  text-align: right;
  background: $white;
  border-bottom: 1px solid $base-border-color;
  .action,
  .button {
    display: inline-block;
    margin: $main-padding 0 $main-padding 10px;
    overflow: hidden;
    vertical-align: middle;
    &.has-amount {
      position: relative;
      padding-right: 40px;
      strong {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        padding: 9px 12px 9px 10px;
        color: $brand-primary;
        border-left: 1px solid $brand-primary;
      }
    }
    &.is-active {
      color: $white;
      background: $brand-primary;
      strong {
        color: $white;
        border-left: 1px solid rgba($white, 0.4);
      }
    }
  }
}
