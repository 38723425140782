// =============================================================================
// Actionbar
// =============================================================================

.actionbar {
  position: fixed;
  top: $navbar-height;
  right: 0;
  bottom: 0;
  z-index: 40;
  width: 650px;
  overflow: auto;
  background-color: $gray-lighter;
  box-shadow: -1px 0 20px rgba($gray-darkest, 0.2);
}

.actionbar-backdrop {
  position: fixed;
  top: $navbar-height;
  right: 0;
  bottom: 0;
  left: $sidebar-width;
  z-index: 40;
  background-color: rgba($gray-darkest, 0.5);
}

.actionbar-header {
  display: block;
  width: inherit;
  padding: 21px $main-padding;
  text-align: center;
  background-color: $white;
  h5 {
    color: $gray;
  }
}

.actionbar-header-right {
  position: absolute;
  top: 15px;
  right: $main-padding;
}

.actionbar-header-left {
  position: absolute;
  top: 15px;
  left: $main-padding;
  .action {
    margin-right: 10px;
    margin-left: 0;
  }
}

.actionbar-content {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  height: calc(100% - 59px);
  padding-top: $main-padding;
  overflow: auto;
}

// Animation
// =============================================================================

.actionbar-enter {
  .actionbar {
    transform: translateX(650px);

    &.is-large {
      transform: translateX(1200px);
    }
  }
  .actionbar-backdrop {
    opacity: 0;
  }
  &.actionbar-enter-active {
    .actionbar {
      transform: translateX(0);
      transition: transform $base-transition-speed ease-in-out;
    }
    .actionbar-backdrop {
      opacity: 1;
      transition: opacity $base-transition-speed linear;
    }
  }
}

.actionbar-exit {
  .actionbar {
    transform: translateX(0);
  }
  .actionbar-backdrop {
    opacity: 1;
  }
  &.actionbar-exit-active {
    .actionbar {
      transform: translateX(650px);
      transition: transform $base-transition-speed ease-in-out;

      &.is-large {
        transform: translateX(1200px);
      }
    }
    .actionbar-backdrop {
      opacity: 0;
      transition: opacity $base-transition-speed linear;
    }
  }
}
