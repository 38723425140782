// =============================================================================
// Tables
// =============================================================================

// Component
// =============================================================================

table {
  width: 100%;
  margin: 0;
  overflow: hidden;
  border-collapse: separate;
  &.with-borders {
    border-top: 1px solid $base-border-color;
    border-bottom: 1px solid $base-border-color;
    border-radius: $base-border-radius;
  }
}

tr {
  // A bit hackish, should fix this.
  height: 45px;
  transition: background-color $base-transition-speed linear;
  &:first-child {
    th:first-child {
      border-top-left-radius: $base-border-radius;
    }
    th:last-child {
      border-top-right-radius: $base-border-radius;
    }
  }
  &:last-child {
    td:first-child {
      border-bottom-left-radius: $base-border-radius;
    }
    td:last-child {
      border-bottom-right-radius: $base-border-radius;
    }
  }
  th:first-child,
  td:first-child {
    border-left: 1px solid $base-border-color;
  }
  th:last-child,
  td:last-child {
    border-right: 1px solid $base-border-color;
  }
  &.is-added {
    background-color: rgba($brand-success, 0.2);
  }
  &.is-active {
    background-color: $gray-lightest;
  }
  &.is-clickable {
    cursor: pointer;
    &:hover {
      background-color: $gray-lightest;
    }
    &:active {
      background-color: $gray-lighter;
    }
  }

  &.tr-disabled {
    color: $gray;
  }

  &.is-draggable {
    cursor: grab;

    td > i {
      position: absolute;
      top: 16px;
      left: 12px;
      font-size: 13px;
    }

    td > span {
      margin-left: 16px;
    }
  }
}

th,
td {
  padding: 7.5px 15px;
  text-align: left;
  vertical-align: middle;

  &.without-padding {
    padding: 0;
    text-align: right;
  }
}

th {
  padding: 10px 15px 8px;
  overflow: hidden;
  font-size: 10px;
  font-weight: $base-font-weight-bold;
  color: $gray-dark;
  text-transform: uppercase;
  background: $gray-lightest;
}

.th-lowercase {
  text-transform: lowercase;
}

td {
  position: relative;
  font-weight: 400;
  border-top: 1px solid $base-border-color;
  span {
    vertical-align: middle;
  }
}

// Modifiers
// =============================================================================
.table-grid {
  table {
    display: grid;
    grid-template-rows: auto 1fr;
    overflow: unset;
    background: $base-border-color;
  }

  thead,
  tbody {
    display: grid;
    background: $base-border-color;
  }

  thead {
    border-bottom: 1px solid $base-border-color;

    tr {
      background: $gray-lightest;
    }
  }

  tbody {
    grid-gap: 1px;

    tr {
      background: $white;
    }
  }

  tr {
    height: unset;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(1px, 1fr));
    grid-auto-rows: minmax(45px, 1fr);
    align-items: center;
    min-width: 0;
  }

  td,
  th {
    border: none;

    &:first-child {
      border-left: none;
    }

    &:last-child {
      border-right: none;
    }
  }
}

.table-sticky {
  table {
    position: relative;
  }

  thead {
    position: sticky;
    top: -15px;
    overflow: hidden;
    z-index: 1;
  }
}

.table-responsive {
  display: block;
  width: 100%;
}

.table-fixed {
  table {
    table-layout: fixed;
  }
}

.table-image {
  margin: -4.5px 10px -4.5px 0;
}

.table-icon {
  height: 16px;
}

.table-status {
  display: inline-block;
  margin-right: 5px;
  vertical-align: middle;
  cursor: default;
  position: relative;

  i {
    font-size: 18px;
    line-height: $base-line-height;
    margin-top: -14px;
  }
  &.is-success i {
    color: $brand-success;
  }
  &.is-danger i {
    color: $brand-danger;
  }
  &.is-gray i {
    color: $gray;
  }

  .metadata {
    position: absolute;
    top: -5px;
    left: 25px;
  }
}

.table-sort {
  display: block;
  padding: 0;
  color: $gray-dark;
  text-transform: uppercase;
  background-color: $gray-lightest;
  border: 0;
  i {
    display: inline-block;
    margin-top: -2px;
    margin-left: 2px;
    font-size: 18px;
    vertical-align: middle;
  }
  &:focus {
    outline: none;
  }
}

// Action buttons
// =============================================================================

.table-actions {
  text-align: right;
  padding: 6.5px 15px;
}

.table-overlay-delete,
.table-overlay-audit {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  width: 420px;
  height: 100%;
  padding: 7.5px 10px;
  text-align: right;
  background: $brand-danger;
  h5 {
    display: inline-block;
    max-width: 280px;
    margin-right: 10px;
    font-size: 12px;
    color: $white;
    vertical-align: middle;
  }
  .action-danger {
    color: $white;
    background: rgba($white, 0.5);
    i {
      color: $white;
    }
    &:hover {
      background: $white;
      i {
        color: $brand-danger;
      }
    }
  }
}

.table-overlay-audit {
  background: $gray-lightest;
  h5 {
    color: $gray-dark;
    small {
      font-weight: 500;
    }
  }
}

// Cells
// =============================================================================

.table-identity-cell {
  display: flex;
  align-items: center;
  .avatar {
    flex-shrink: 0;
  }
}

// Animation
// =============================================================================

.table-overlay-enter {
  opacity: 0;
  transform: translateX(380px);
  &.table-overlay-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: all $base-transition-speed linear;
  }
}

.table-overlay-exit {
  opacity: 1;
  transform: translateX(0);
  &.table-overlay-exit-active {
    opacity: 1;
    transform: translateX(380px);
    transition: all $base-transition-speed linear;
  }
}
