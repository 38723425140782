// =============================================================================
// Pages
// =============================================================================

.page {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  text-align: center;
  background: $brand-primary;
  .button {
    margin: 0 5px;
  }
}

.page-logo {
  position: absolute;
  right: 0;
  bottom: 20px;
  left: 50%;
  display: inline-block;
  width: 120px;
  margin-left: -60px;
  opacity: 0.3;
}

.page-title {
  display: inline-block;
  margin-bottom: 25px;
  color: rgba($white, 0.8);
  i {
    margin-bottom: 35px;
    font-size: 90px;
    line-height: 1;
    color: $white;
  }
  &.is-beating i {
    animation: heartbeat 0.5s ease-in-out 4;
  }
  &.is-shaking i {
    animation: shake 0.3s ease-in-out 4;
  }
  &.is-spinning i {
    animation: spin 0.8s linear infinite;
  }
}

.page-container {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  margin-top: -147px;
}

@media all and (max-height: 420px) {
  .page-container {
    position: relative;
    top: 0;
    padding: 50px 20px 80px;
    margin-top: 0;
  }

  .page-logo {
    position: relative;
    left: 0;
    margin-left: 0;
  }
}
