// =============================================================================
// Base
// =============================================================================

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  font-size: $base-font-size;
}

body {
  margin: 0 auto;
  font-family: $base-font-family;
  font-weight: $base-font-weight;
  line-height: $base-line-height;
  color: $base-font-color;
}

.wrapper {
  /* autoprefixer grid: on */
  display: grid;
  grid-template-columns: $sidebar-width calc(100% - #{$sidebar-width});
  grid-template-rows: $navbar-height auto 1fr;
  height: 100vh;
  min-width: $min-screen-width;
}

main {
  /* autoprefixer grid: on */
  grid-area: 3 / 2 / 4 / 3;
  overflow: auto;
  padding-top: $main-padding;
  transition: padding-right $base-transition-speed ease-in-out;
  background: $gray-lighter;

  &.without-padding {
    padding-top: 0;
  }
}

// Typography
// =============================================================================

p {
  margin: 0;
  font-weight: $base-font-weight;
}

b,
strong {
  font-weight: $base-font-weight-bold;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  line-height: 1.25;
}

h1 {
  font-size: 32px;
  font-weight: 400;
}

h2 {
  font-size: 24px;
  font-weight: 400;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 16px;
  font-weight: 500;
}

h5 {
  font-size: $base-font-size;
}

h6 {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

a {
  color: $base-accent-color;
  text-decoration: none;
  transition: color $base-transition-speed linear;
  &:hover,
  &:active {
    color: $base-accent-highlighted-color;
  }
  &:focus {
    color: $base-accent-highlighted-color;
    outline: none;
  }
}

abbr,
acronym {
  cursor: help;
  border-bottom: 1px dotted $base-border-color;
}

blockquote {
  padding-left: $base-line-height / 2;
  margin: $base-line-height 0;
  color: lighten($base-font-color, 15);
  border-left: 2px solid $base-border-color;
}

cite {
  font-style: italic;
  color: $base-accent-color;
}

// Other
// =============================================================================

hr {
  margin: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid $base-border-color;
  border-left: 0;
}

img {
  display: block;
  max-width: 100%;
  margin: 0;
}

::selection {
  color: $white;
  background: $brand-primary;
}

// Spacing
// =============================================================================

.p-0 {
  padding: 0;
}
.p-1 {
  padding: 0.25rem;
}
.p-2 {
  padding: 0.5rem;
}
.p-3 {
  padding: 0.75rem;
}

.pt-0 {
  padding-top: 0;
}
.pt-1 {
  padding-top: 0.25rem;
}
.pt-2 {
  padding-top: 0.5rem;
}
.pt-3 {
  padding-top: 0.75rem;
}

.pb-0 {
  padding-bottom: 0;
}
.pb-1 {
  padding-bottom: 0.25rem;
}
.pb-2 {
  padding-bottom: 0.5rem;
}
.pb-3 {
  padding-bottom: 0.75rem;
}

.m-0 {
  margin: 0;
}
.m-1 {
  margin: 0.25rem;
}
.m-2 {
  margin: 0.5rem;
}
.m-3 {
  margin: 0.75rem;
}

.mt-0 {
  margin-top: 0;
}
.mt-1 {
  margin-top: 0.25rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mt-3 {
  margin-top: 0.75rem;
}

.mb-0 {
  margin-bottom: 0;
}
.mb-1 {
  margin-bottom: 0.25rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mb-3 {
  margin-bottom: 0.75rem;
}
