// =============================================================================
// Paginations
// =============================================================================

.pagination {
  display: block;
  height: 40px;
  margin: 0 $main-padding $main-padding;
}

.pagination-info {
  display: inline-block;
  height: 40px;
  padding: 12px 0 12px 15px;
  font-size: 12px;
  font-weight: 500;
  color: $gray;
}

.pagination-actions {
  float: right;
  padding: 0;
  margin: 0;
  text-align: right;
  list-style: none;
  li {
    display: inline-block;
    vertical-align: middle;
  }
}

.pagination-entry {
  display: inline-block;
  height: 40px;
  padding: 5px 15px;
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  color: $gray;
  text-align: center;
  background: transparent;
  border: 0;
  border-radius: $base-border-radius;
  transition: all $base-transition-speed linear;
  i {
    font-size: 18px;
    line-height: 1.6;
  }
  &:hover {
    color: $gray-darker;
  }
  &.is-current {
    color: $gray-darker;
    background: $white;
  }
  &.is-button {
    padding: 5px 10px;
  }
  &:focus {
    outline: none;
  }
}
