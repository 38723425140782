// =============================================================================
// Toasts
// =============================================================================

.Toastify {
  position: fixed;
}

// Settings
// =============================================================================

@mixin toast-generator($color) {
  background: mix($color, $white, 20%);

  i,
  ul li,
  button {
    color: $color;
    opacity: 1;
  }
}

// Component
// =============================================================================

.toast {
  font-family: $base-font-family;
  border-radius: $base-border-radius;
  padding: 10px;

  .alert {
    display: flex;
    align-items: center;
    background: transparent;
    padding: 5px;
  }
}

// Descendant
// =============================================================================

.toast-success {
  @include toast-generator($brand-success);
}
.toast-danger {
  @include toast-generator($brand-danger);
}
.toast-warning {
  @include toast-generator($brand-warning);
}
.toast-primary {
  @include toast-generator($brand-primary);
}

.toast-progress {
  &.toast-progress-success {
    background: $brand-success;
  }
  &.toast-progress-danger {
    background: $brand-danger;
  }
  &.toast-progress-warning {
    background: $brand-warning;
  }
  &.toast-progress-primary {
    background: $brand-primary;
  }
}
