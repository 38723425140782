// =============================================================================
// Sidebar
// =============================================================================

.sidebar {
  /* autoprefixer grid: on */
  grid-area: 2 / 1 / 4 / 2;
  background: $gray-darker;
}

.sidebar-links {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  overflow-x: visible;
  overflow-y: auto;
  height: 100%;

  li a,
  li div {
    position: relative;
    display: block;
    height: 60px;
    padding: 18px 18px 18px 14px;
    margin-top: 0;
    font-size: 13px;
    font-weight: 400;
    color: $gray-light;
    border-left: 4px solid transparent;
    transition: all $base-transition-speed linear;
    text-align: left;
    cursor: pointer;

    i {
      display: inline-block;
      font-size: 22px;
      line-height: 1.2;
      transition: color $base-transition-speed linear;
      vertical-align: middle;
    }

    span {
      display: inline-block;
      margin-top: 1px;
      margin-left: 5px;
      font-weight: 600;
      opacity: 0;
      vertical-align: middle;
    }

    &:hover,
    &.is-active {
      color: $white;
      background-color: darken($gray-darker, 5);
      i {
        color: $white;
      }
    }

    &.is-active {
      border-color: $brand-primary;
    }
  }

  .sidebar-spacer {
    flex-grow: 1;
    min-height: 40px;
  }

  li.warning {
    height: 60px;
    position: relative;
    display: block;
    background: $brand-danger;
    padding: 18px 18px 18px 14px;
    margin-top: 0;
    font-size: 13px;
    font-weight: 400;
    color: $white;
    border-left: 4px solid transparent;
    transition: all $base-transition-speed linear;
    text-align: center;

    &:hover {
      cursor: pointer;
    }
  }
}

.sidebar-menu {
  position: absolute;
  bottom: 60px;
  left: $sidebar-width;
  z-index: 500;
  width: 300px;
  padding: 15px 0;
  margin: 0;
  background: $gray-darkest;
  border-radius: $base-border-radius;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  list-style: none;
  box-shadow: 0 0 10px rgba($black, 0.4);

  li {
    padding: 0;

    a {
      display: block;
      padding: 10px 18px 10px 14px;
      height: auto;

      span {
        opacity: 1 !important;
      }
    }

    h6 {
      padding: 10px 18px 5px;
      margin: 0;
      color: $gray;
      font-weight: 600;
      font-size: 11px;
    }
  }
}
