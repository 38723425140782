// =============================================================================
// Forms
// =============================================================================

// Component
// =============================================================================

fieldset {
  padding: 20px;
  margin: 0;
  background: $gray-lightest;
  border: 1px solid $base-border-color;
}

label,
input,
select,
textarea {
  display: inline-block;
  font-family: inherit;
  line-height: $base-line-height;
}

label {
  position: relative;
  font-size: $base-font-size;
  font-weight: 500;
  color: darken($gray, 10);

  small {
    position: absolute;
    font-size: 9px;
    color: $gray-light;
    text-transform: uppercase;
    top: 40px;
    left: 20px;
  }
}

input,
textarea {
  padding: 5px 15px;
  font-family: $base-font-family;
  font-size: $base-font-size;
  font-weight: 500;
  color: $base-font-color;
  background-color: $white;
  border: 1px solid $gray-light;
  border-radius: $base-border-radius;
  transition: border-color $base-transition-speed linear;
  appearance: none;
  &:hover {
    border-color: darken($base-border-color, 10);
  }
  &:focus,
  &:active {
    border-color: $base-accent-color;
    outline: none;
  }
  &:disabled {
    color: $gray;
    cursor: not-allowed !important;
  }
}

input::placeholder {
  color: $gray-light;
  margin: 0;
}

// Descendant
// =============================================================================

.form-header {
  position: relative;
  display: block;
  padding: 20px 20px 18px;
  background: $gray-lightest;
  border-bottom: 1px solid $base-border-color;
  border-top-right-radius: $base-border-radius;
  border-top-left-radius: $base-border-radius;
  + .alert {
    margin-top: -1px;
    border-radius: 0;
  }
  .actionbar-header-right {
    right: 20px;
  }
}

.form-item {
  position: relative;
  display: block;
  font-size: 0;
  border-bottom: 1px solid $base-border-color;
  label {
    @include truncate();
    display: inline-block;
    width: 40%;
    padding: 20px;
    vertical-align: middle;
    &.is-top {
      vertical-align: top;
    }
  }
  input,
  textarea {
    display: inline-block;
    width: 60%;
    max-width: 60%;
    padding: 20px;
    vertical-align: middle;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    &[type='number'] {
      appearance: textfield;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        margin: 0;
        appearance: none;
      }
    }
    &.with-unit {
      padding: 20px 60px 20px 20px;
    }
  }
  &:last-child {
    border-bottom: 0;
  }
  &.has-error {
    .alert {
      display: grid;
      grid-template-columns: 20px 1fr;
      padding: 10px 20px;
      border-radius: 0;
    }
    label {
      color: $brand-danger;
    }
  }
}

.form-item--filter {
  background: $white;

  label {
    width: 30%;
    max-width: 30%;
  }

  input {
    width: 70%;
    max-width: 70%;
  }

  .form-select {
    position: relative;

    > button {
      position: absolute;
      top: 0;
    }

    button {
      margin: 0;
      padding: 12px;
    }
  }

  .form-checkbox-group > div:first-child {
    margin-top: 30px;
  }

  .form-checkbox-group {
    margin-left: 0;
    margin-bottom: 0;
  }

  label,
  input {
    padding: 12px;
  }
}

.form-select,
.form-output {
  display: inline-block;
  width: 60%;
  padding: 0;
  font-size: $base-font-size;
  vertical-align: middle;
}

.form-output {
  padding: 0 20px;
}

.form-label-link {
  display: inline-block;
  padding: 0;
  margin-bottom: 5px;
  margin-left: 25px;
  font-size: 11px;
  font-weight: 400;
  color: $gray;
  text-transform: uppercase;
  background: transparent;
  border: 0;
  &:active,
  &:focus {
    outline: none;
  }
}

.form-unit {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: $base-font-size;
  font-weight: 500;
  color: $gray-light;
}

// File upload
// =============================================================================

.form-file {
  display: inline-block;
  width: 55%;
  padding: 11px 20px;
  vertical-align: middle;

  p {
    @include truncate();
    display: inline-block;
    max-width: 60%;
    font-size: 12px;
    font-weight: 700;
    color: $gray-dark;
    vertical-align: middle;
  }

  button {
    padding-top: 3px;
    margin-bottom: 10px;
    float: left;
  }
}

.form-file-image {
  position: relative;
  display: inline-block;
  width: 225px;
}

.form-file-document {
  position: relative;
  display: inline-block;
  width: 175px;
  height: 18px;
  margin: 11px 0;
}

.form-file-actions {
  position: absolute;
  top: 16.5px;
  right: 20px;

  .loader-wrapper {
    float: left;
    margin-left: 10px;
  }
}

// Checkboxes and radios
// =============================================================================

.form-checkbox-group {
  margin-left: 20px;
  margin-bottom: 20px;
}

.form-option {
  display: block;
  padding: 0;
  margin: 5px 0;
  font-weight: 500;
  color: lighten($gray-dark, 10);
  background: transparent;
  border: 0;
  border-radius: $base-border-radius;
  transition: background-color $base-transition-speed linear;
  span,
  i {
    display: inline-block;
    vertical-align: middle;
  }
  i {
    margin-right: 7px;
    font-size: 18px;
    color: $gray;
    transition: color $base-transition-speed linear;
  }
  &:active,
  &:focus {
    outline: none;
  }
  &:hover i {
    color: $gray-dark;
  }
  &.is-active i {
    color: $brand-primary;
  }
}

// Form toggle
// =============================================================================

.form-toggle-button {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 21px;
  height: 21px;
  margin: 2px;
  background: $white;
  border-radius: 50%;
  transform: translateX(0);
  transition: transform 0.1s linear;
}

.form-toggle {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
  margin-left: 20px;
  text-align: left;
  vertical-align: middle;
  cursor: pointer;
  background: $gray-light;
  border-radius: 12.5px;
  transition: background 0.1s linear;
  &.is-active {
    background: $brand-primary;
    .form-toggle-button {
      transform: translateX(25px);
    }
  }
}
