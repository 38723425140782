// =============================================================================
// Modal
// =============================================================================

.modal {
  position: fixed;
  top: $navbar-height + 50;
  left: calc(50% + #{$sidebar-width / 2});
  z-index: 40;
  width: 100%;
  max-width: 500px;
  max-height: calc(100vh - #{$navbar-height + 50 + 50});
  display: flex;
  flex-direction: column;
  text-align: center;
  transform: translateX(-50%);
  border-radius: $base-border-radius;
  &.is-small {
    max-width: 400px;
  }
  &.is-large {
    max-width: 600px;
  }
  &.is-extra-large {
    max-width: 900px;
  }
  &.is-auto {
    width: auto;
    max-width: 1365px;
  }
}

.modal-container {
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  display: block;
  text-align: left;
  background-color: $white;
  border-radius: $base-border-radius;
  box-shadow: 0 0 20px rgba($gray-darkest, 0.4);
  section {
    margin-bottom: 0;
  }
}

.modal-backdrop {
  position: fixed;
  top: $navbar-height;
  right: 0;
  bottom: 0;
  left: $sidebar-width;
  z-index: 40;
  background-color: rgba($gray-darkest, 0.5);
}

.modal-header {
  z-index: 60;
  position: fixed;
  display: block;
  width: 100%;
  padding: 21px $main-padding;
  text-align: center;
  background-color: $white;
  border-bottom: 1px solid $base-border-color;
  border-top-right-radius: $base-border-radius;
  border-top-left-radius: $base-border-radius;
  h5 {
    color: $gray;
  }
}

.modal-header-right {
  position: absolute;
  top: 15px;
  right: $main-padding;
}

.modal-content-container {
  height: 100%;
  padding-top: 60px;
}

.modal-content {
  display: block;
  padding: $main-padding;
  &.is-centered {
    text-align: center;
  }
  &.without-actions {
    margin-bottom: 26px;
  }
  &.without-padding {
    padding: 0;
  }
  &.without-align {
    text-align: start;
  }
  &.is-gray {
    padding: $main-padding 0;
    background: $gray-lighter;
    .button {
      display: inline-block;
      margin: $main-padding $main-padding 0 0;
    }
  }
  &.has-table {
    padding: 0;
    th,
    td {
      border-left: 0;
      border-right: 0;
      border-radius: 0 !important;
    }
  }
}

.modal-content-entry {
  display: block;
  margin-bottom: 10px;
  font-weight: 400;
  &:last-of-type {
    margin-bottom: 0;
  }
}

.modal-footer {
  display: block;
  padding: 0 $main-padding;
  text-align: center;
  .button {
    margin: 0 10px $main-padding;
  }
}

// Animation
// =============================================================================

.modal-enter {
  position: relative;
  z-index: 50;
  opacity: 0;
  &.modal-enter-active {
    opacity: 1;
    transition: opacity $base-transition-speed linear;
  }
}

.modal-exit {
  position: relative;
  z-index: 50;
  opacity: 1;
  &.modal-exit-active {
    opacity: 0;
    transition: opacity $base-transition-speed linear;
  }
}
