// =============================================================================
// Datepicker
// =============================================================================

.DayPicker {
  position: absolute;
  top: 100%;
  z-index: 100;
  display: none;
  flex-wrap: wrap;
  justify-content: center;
  width: 55%;
  padding-bottom: 25px;
  background: $white;
  border: 1px solid $base-border-color;
  box-shadow: 0 1px 0 rgba($gray-darkest, 0.06);
  user-select: none;

  &:focus {
    outline: none;
  }

  &.is-open {
    display: flex;
  }
}

.DayPicker-NavBar {
  position: absolute;
  right: 0;
  left: 0;
}

.DayPicker-NavButton {
  position: absolute;
  z-index: 30;
  width: 1.5rem;
  height: 1.5rem;
  margin: 20px 15px 0;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0.3;
}

.DayPicker-NavButton--prev {
  background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjMDAwMDAwIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gICAgPHBhdGggZD0iTTE1LjQxIDcuNDFMMTQgNmwtNiA2IDYgNiAxLjQxLTEuNDFMMTAuODMgMTJ6Ii8+ICAgIDxwYXRoIGQ9Ik0wIDBoMjR2MjRIMHoiIGZpbGw9Im5vbmUiLz48L3N2Zz4=);
}

.DayPicker-NavButton--next {
  right: 0;
  background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjMDAwMDAwIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gICAgPHBhdGggZD0iTTEwIDZMOC41OSA3LjQxIDEzLjE3IDEybC00LjU4IDQuNTlMMTAgMThsNi02eiIvPiAgICA8cGF0aCBkPSJNMCAwaDI0djI0SDB6IiBmaWxsPSJub25lIi8+PC9zdmc+);
}

.DayPicker-Month {
  position: relative;
  z-index: 20;
  display: table;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  user-select: none;
}

.DayPicker-Caption {
  display: table-caption;
  padding: 17px 0 10px;
  text-align: center;
  select {
    display: inline-block;
    width: 77px;
    margin: 0 2.5px;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    cursor: pointer;
    background: $white;
    border: 0;
    appearance: none;
    &:focus {
      outline: none;
    }
  }
}

.DayPicker-Weekdays {
  display: table-header-group;
}

.DayPicker-WeekdaysRow {
  display: table-row;
}

.DayPicker-Weekday {
  display: table-cell;
  padding: 0.5rem;
  font-size: 0.875em;
  color: $gray;
  text-align: center;
}

.DayPicker-Body {
  display: table-row-group;
}

.DayPicker-Week {
  display: table-row;
}

.DayPicker-Day {
  display: table-cell;
  padding: 0.5rem;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid $base-border-color;
  transition: background $base-transition-speed linear;
  &:first-child {
    border-left: 0;
  }
  &:last-child {
    border-right: 0;
  }
  &:hover {
    background: $gray-lightest;
  }
  &:focus {
    outline: none;
  }
}

.DayPicker--interactionDisabled .DayPicker-Day {
  cursor: default;
}

// Modifiers
// =============================================================================

.DayPicker-Day--today {
  font-weight: 500;
  color: $brand-primary;
}

.DayPicker-Day--disabled {
  color: $gray;
  cursor: not-allowed;
  background-color: $gray-lighter;
  &:hover {
    background: $gray-lighter;
  }
}

.DayPicker-Day--outside {
  cursor: default;
  &:hover {
    background: $white;
  }
}

.DayPicker-Day--future {
  color: $gray;
  cursor: default;
  &:hover {
    background: $white;
  }
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  color: $white;
  background-color: $brand-primary;
}

.DayPicker-Day--selected.DayPicker-Day--disabled {
  color: $white;
  background-color: lighten($brand-primary, 15);
}
