// =============================================================================
// Colors
// =============================================================================

// Settings
// =============================================================================

@mixin color-generator {
  display: inline-block;
  width: 13px;
  height: 13px;
  margin-right: 10px;
  vertical-align: middle;
  border-radius: $base-border-radius;
}

// Component(s)
// =============================================================================

.color-wrapper {
  display: inline-block;
  margin-right: 20px;
  vertical-align: middle;
  h6 {
    display: inline-block;
    margin-top: 2px;
    font-size: 12px;
    color: $gray;
    vertical-align: middle;
  }
}

.color-preview {
  @include color-generator();
}

.color-led {
  @include color-generator();
  border-radius: 50%;
}

.color-picker {
  .color-preview {
    cursor: pointer;
    opacity: 0.3;
    transition: opacity $base-transition-speed linear;
    &:hover {
      opacity: 0.7;
    }
    &.is-selected {
      opacity: 1;
    }
  }
}
