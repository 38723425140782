// =============================================================================
// Selects
// =============================================================================

.Select {
  height: 62px;

  .Select__control {
    padding: 17.25px 20px;
    overflow: hidden;
    background: transparent;

    font-size: $base-font-size;
    font-weight: 500;

    border: none;
    outline: none;
    box-shadow: none;
    border-radius: 0;

    &.Select__control--is-focused {
      border: none;
      outline: none;
      box-shadow: none;
      border-radius: 0;
    }

    .Select__value-container {
      padding: 0;
      cursor: pointer;

      > div {
        margin: 0;
      }

      .Select__placeholder,
      .Select__single-value,
      .Select__input {
        margin: 0;
      }

      .Select__placeholder {
        color: $gray-light;
      }

      .Select__single-value {
        color: $gray-dark;
      }

      .Select__multi-value {
        margin-right: 5px;
        margin-bottom: 2px;
        background: $brand-primary;

        .Select__multi-value__label {
          cursor: default;
          padding: 0 7.5px;
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
          color: white;
          font-size: 10px;
          line-height: 20px;
        }

        .Select__multi-value__remove {
          cursor: pointer;
          padding: 3px;
          border-left: 1px solid darken($brand-primary, 5);
          border-bottom-left-radius: 3px;
          border-top-left-radius: 3px;
          color: white;
          font-size: 10px;

          &:hover {
            background: $brand-danger;
          }
        }
      }

      .Select__input > input {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  .Select__menu {
    border-radius: 0;
    box-shadow: none;
    margin: 0;
    border-top: 1px solid $gray-lighter;
    border-left: 1px solid $gray-lighter;
    border-bottom: 1px solid $gray-lighter;
    z-index: 100;

    .Select__menu-list {
      padding: 0;

      .Select__option,
      .Select__menu-notice {
        padding: 10px 20px;
        font-size: $base-font-size;
        font-weight: 500;
        background: $white;
        transition: background $base-transition-speed linear;
        text-align: left;
      }

      .Select__menu-notice {
        color: $gray-light;
      }

      .Select__option {
        color: $gray-dark;

        &.Select__option--is-selected {
          background: transparent;
          color: $gray-dark;
        }

        &.Select__option--is-focused {
          background: $gray-lightest;
        }

        &:hover {
          cursor: pointer;
          background: $gray-lightest;
        }
      }
    }
  }

  .Select__indicator-separator {
    display: none;
  }

  &.Select__multi {
    height: unset;
  }

  &.Select--is-full {
    width: 100%;
    border-bottom: 1px solid $gray-lighter;

    .Select__menu {
      border-left: none;
    }
  }

  &.Select--is-single {
    float: left;
    width: 200px;
    text-align: left;
    border-right: 1px solid $gray-lighter;
  }

  &.Select--is-disabled {
    cursor: not-allowed;

    .Select__value-container {
      cursor: not-allowed;

      .Select__single-value {
        color: $gray;
      }

      .Select__multi-value .Select__multi-value__label,
      .Select__multi-value .Select__multi-value__remove {
        cursor: not-allowed;
      }
    }

    .Select__indicators
      .Select__dropdown-indicator-container
      .Select__dropdown-indicator {
      cursor: not-allowed;
      border-color: $gray-light transparent transparent;
    }

    .Select__indicators
      .Select__clear-indicator-container
      .Select__clear-indicator {
      cursor: not-allowed;
      border: $gray-light;
    }
  }

  &.Select--is-focused input {
    cursor: text;
  }
}

// =============================================================================
// Clear Indicator
// =============================================================================

.Select__clear-indicator-container {
  position: relative;
  width: 10px;
  height: 100%;
  margin-right: 10px;

  .Select__clear-indicator {
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    color: $gray;
    cursor: pointer;
    font-weight: 500;
  }
}

// =============================================================================
// Dropdown Indicator
// =============================================================================

.Select__dropdown-indicator-container {
  position: relative;
  width: 10px;
  height: 100%;

  .Select__dropdown-indicator {
    display: block;
    width: 0;
    height: 0;
    position: relative;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    border-color: $gray transparent transparent;
    border-style: solid;
    border-width: 5px 5px 0;
    content: ' ';

    &.Select__dropdown-indicator--active {
      border-color: $gray-dark transparent transparent;
    }
  }
}

// =============================================================================
// Time Select
// =============================================================================

.Select__time-container {
  display: flex;
  float: right;
  position: relative;
  height: 62px;

  > .Select {
    flex: 1;

    &.Select--is-disabled
      .Select__clear-indicator-container
      .Select__clear-indicator {
      cursor: not-allowed;
      border: $gray-light;
    }
  }

  > p {
    font-weight: 500;
    font-size: $base-font-size;
    line-height: 62px;
    vertical-align: middle;
    text-align: center;
  }

  .Select__clear-indicator-container {
    margin-right: 20px;
  }
}

// =============================================================================
// Datepicker Select
// =============================================================================

.Select__date-picker {
  display: flex;
  float: right;

  &.has-value
    .Select__dropdown-indicator-container
    .Select__dropdown-indicator {
    border-color: $gray-dark transparent transparent;
  }

  .Select__input-zone {
    flex: 1;

    input {
      padding: 20px 0 0 20px;
      width: 100%;
      max-width: 100%;
    }
  }

  .Select__dropdown-indicator-container {
    margin-right: 20px;
  }
}

// =============================================================================
// Navbar Select
// =============================================================================

.Select__navbar {
  height: 60px;

  .Select__control {
    padding: 17px 20px;

    .Select__value-container {
      padding-left: 25px;

      .Select__input {
        color: $white;
      }

      .Select__single-value {
        color: $white;
      }
    }

    .Select__clear-indicator-container,
    .Select__dropdown-indicator-container {
      display: none;
    }

    .Select__loading-indicator {
      color: $white !important;
    }

    &.Select__control--menu-is-open {
      background: darken($brand-primary, 10);
    }
  }

  .Select__menu {
    border: 0;

    .Select__menu-list {
      .Select__menu-notice {
        background: $gray-dark;
        color: $gray;
      }
    }
  }
}

// =============================================================================
// Filters
// =============================================================================

.Select--filter-wrapper {
  height: 22px;

  .Select {
    height: 22px;
    float: left;

    .Select__control {
      padding: 0 20px;
      height: 22px;
      min-height: 22px;

      .Select__value-container {
        height: 22px;
      }
    }

    .Select__menu {
      margin-top: 10px;
    }
  }
}

.Select--filter-wrapper-multi {
  .Select {
    float: left;

    .Select__control {
      padding: 0 20px 0 0;
      min-height: 22px;
    }

    .Select__menu {
      margin-top: 10px;
    }
  }
}

.Select--filter-wrapper-search {
  height: 22px;

  .Select {
    height: 22px;
    float: left;

    .Select__control {
      padding: 0 20px 0 0;
      height: 22px;
      min-height: 22px;

      .Select__value-container {
        height: 22px;
      }
    }

    .Select__menu {
      margin-top: 10px;
    }
  }
}

.Select--date-filter {
  position: relative;

  .Select {
    height: 22px;

    &.Select__date-picker {
      input {
        padding: 0 20px;
        border: 0;
      }
    }

    .select__control {
      padding: 8.75px 20px;
    }
  }

  .DayPicker {
    width: 310px;
    top: 145%;
  }
}

// =============================================================================
// Dynamic Filters
// =============================================================================

.Select--dynamic-filter {
  .Select {
    height: 45px;
    width: 70%;

    &.Select__date-picker {
      input {
        padding: 12px 0;
      }
    }

    .Select__control {
      padding: 8.75px 20px;
    }
  }

  .DayPicker {
    width: 100%;
  }
}

// =============================================================================
// Animations
// =============================================================================

.Select__control--menu-is-open,
.Select__date-picker--menu-is-open {
  .Select__dropdown-indicator {
    transform: translateY(-50%) rotate(180deg);
  }
}
