// =============================================================================
// Navbar
// =============================================================================

.navbar {
  /* autoprefixer grid: on */
  grid-area: 1 / 1 / 2 / 3;
  background: $brand-primary;
}

// Left side
// =============================================================================

.navbar-left {
  float: left;
}

.navbar-logo {
  display: inline-block;
  width: $sidebar-width;
  height: $navbar-height;
  padding: 22px 5px;
  text-align: center;
  // background-color: darken($brand-primary, 5);
  background-color: $gray-lighter;
  transition: background $base-transition-speed linear;
  &:hover {
    background-color: $white;
  }
}

.navbar-search {
  position: relative;
  display: inline-block;
  width: 450px;
  vertical-align: top;
  .material-icons {
    position: absolute;
    top: 22px;
    left: 20px;
    font-size: 18px;
    color: $white;
  }
}

// Right side
// =============================================================================

.navbar-right {
  float: right;
  text-align: right;
}

.navbar-account {
  display: inline-block;
  padding: 15px 20px;
  @media (max-width: 1070px) {
    padding: 15px;
  }
  font-size: 13px;
  font-weight: 700;
  color: $white;
  vertical-align: middle;
  span {
    display: inline-block;
    vertical-align: middle;
  }
  &:hover,
  &:active,
  &:focus {
    color: $white;
  }
}

button.navbar-link {
  height: 60px;
  color: $white;
  vertical-align: middle;
  font-weight: 500;
  margin-right: 20px;
}

.navbar-notifications-count {
  position: absolute;
  top: 12px;
  right: 12px;
  display: block;
  width: 20px;
  height: 20px;
  font-size: 9.5px;
  font-weight: 900;
  line-height: 1.4;
  color: $white;
  text-align: center;
  background: $brand-danger;
  border: 3px solid darken($brand-primary, 5);
  border-radius: 50%;
}

.navbar-notifications {
  position: relative;
  display: inline-block;
  height: 60px;
  padding: 18.5px 20px 0;
  color: $white;
  vertical-align: middle;
  background-color: darken($brand-primary, 5);
  transition: background $base-transition-speed linear;
  &:hover {
    color: $white;
    background-color: darken($brand-primary, 10);
    i {
      animation: heartbeat 200ms linear 2;
    }
    .navbar-notifications-count {
      border-color: darken($brand-primary, 10);
    }
  }
}
