// =============================================================================
// Mixins
// =============================================================================

// Handling breakpoints for media queries.
// @param {Number} $val the media query specification.

@mixin breakpoint($val) {
  @if $val == small {
    @media all and (max-width: 767px) {
      @content;
    }
  } @else if $val == medium {
    @media all and (max-width: 1070px) {
      @content;
    }
  } @else if $val == large {
    @media all and (max-width: 1250px) {
      @content;
    }
  } @else {
    @warn 'The parameter #{$val} is not valid';
  }
}

// Make sure text overflows with ...

@mixin truncate() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
