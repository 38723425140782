// =============================================================================
// Avatars
// =============================================================================

.avatar {
  display: inline-block;
  width: 40px;
  height: 40px;
  vertical-align: middle;
  background-size: cover !important;
  border-radius: 50%;
  &.is-mini {
    width: 22px;
    height: 22px;
  }
  &.is-small {
    width: 30px;
    height: 30px;
  }
  &.is-medium {
    width: 45px;
    height: 45px;
  }
  &.is-large {
    width: 62px;
    height: 62px;
  }
  &.with-margin-small {
    margin-right: 10px;
  }
  &.with-margin {
    margin-right: 20px;
  }
  &.is-transparent {
    opacity: 0.5;
  }
  &.with-margin-left {
    margin-left: 10px;
  }
}
