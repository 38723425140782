// =============================================================================
// Loaders
// =============================================================================

.loader-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 85px;
  height: 85px;
  margin-top: -42.5px;
  margin-left: -42.5px;

  &.is-small,
  &.is-action {
    width: 30px;
    height: 30px;
    position: relative;

    .loader {
      width: 30px;
      height: 30px;

      svg {
        display: none;
      }
    }
  }

  &.is-small {
    float: left;
    top: 0;
    left: 0;
    margin-top: 0;
    margin-left: 0;
  }

  &.is-action {
    width: 30px;
    height: 30px;
    display: inline-block;
    vertical-align: middle;
    left: unset;
    right: unset;
    margin-left: unset;
    margin-top: unset;
  }
}

.loader {
  position: relative;
  display: block;
  width: 85px;
  height: 85px;
  text-align: center;
  border: 4px solid tranparent;
  border-radius: 50%;
  box-shadow: inset 0 0 0 4px rgba($brand-primary, 0.2);
  &:after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    border-top: 4px solid $brand-primary;
    border-right: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 4px solid transparent;
    border-radius: 50%;
    content: '';
    animation: spin 0.5s infinite linear;
  }
  img,
  svg {
    display: inline-block;
    margin: 7.5px;
    width: 70px;
    height: 70px;
    opacity: 0.2;
  }
}

.loader-placeholder-wrapper {
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  .loading-placeholder {
    display: inline-block;
    margin: 0 15px 0 15px;
    height: 15px;
    border-radius: 3px;
    background-color: $gray-lighter;
    animation: blink 2s ease-in-out 5;
  }
}
