// =============================================================================
// Filters
// =============================================================================

.filter .form-item label {
  width: 20%;
}

.filter .form-item > div {
  width: 80%;
}

.filter-entry {
  display: block;
  padding: 15px;
  border-top: 1px solid $base-border-color;
  label {
    display: inline-block;
    width: 20%;
    vertical-align: middle;
  }
  &.is-first {
    border-top: 0;
  }
}

.filter-toggle {
  position: absolute;
  top: 18px;
  right: 15px;
  padding: 0;
  line-height: 0;
  color: lighten($gray, 10);
  cursor: pointer;
  background: transparent;
  border: 0;
  transition: all $base-transition-speed linear;
  &:hover {
    color: $gray;
  }
  &:focus,
  &:active {
    outline: none;
  }
  &.is-active {
    transform: rotate(180deg);
  }
}

.filter-toggle-wrapper {
  display: block;
  max-height: 0;
  transition: max-height $base-transition-speed linear;
  overflow: hidden;
  &.is-active {
    max-height: 400px;
    overflow: visible;
  }
}

.filter-actions {
  display: inline-block;
  width: 80%;
  vertical-align: middle;
  padding-right: 24px;
  .loading-form-option {
    display: inline-block;
    margin: 0 35px 0 0;
    height: 15px;
    border-radius: 3px;
    background-color: $gray-lighter;
    animation: blink 2s ease-in-out 5;
  }
  .loading-form-option:nth-child(2) {
    animation-delay: 0.2s;
  }
  .loading-form-option:nth-child(3) {
    animation-delay: 0.4s;
  }
  .form-option {
    display: inline-block;
    margin: 0 35px 0 0;
  }

  .Select {
    width: 100%;
  }
}

// The subnav searchbar underneath the main content.

.filter-search {
  display: block;
  width: 100%;
  padding: 0;
  border: 0;
  &::placeholder {
    font-weight: 500;
    color: $gray;
  }
  &:hover,
  &:focus,
  &:active {
    border-color: $base-border-color;
  }
}
