// =============================================================================
// Integration
// =============================================================================

.integration {
  // _base.scss
  .wrapper {
    /* autoprefixer grid: on */
    grid-template-columns: 100%;
    grid-template-rows: auto 1fr;
  }
  main {
    /* autoprefixer grid: on */
    grid-area: 2 / 1 / 3 / 2;
  }

  // _actionbar.scss
  .actionbar {
    top: 0;
  }
  .actionbar-backdrop {
    top: 0;
    left: 0;
  }

  // _modal.scss
  .modal {
    top: 50px;
    left: 50%;
  }
  .modal-backdrop {
    top: 0;
    left: 0;
  }

  // _subnav.scss
  .subnav {
    /* autoprefixer grid: on */
    grid-area: 1 / 1 / 2 / 2;
  }

  // _planning.scss
  .planning-wrapper.is-small {
    left: 0;
  }
}
