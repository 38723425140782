// =============================================================================
// Sections
// =============================================================================

section {
  position: relative;
  display: block;
  margin: 0 $main-padding $main-padding;
  background-color: $white;
  border-radius: $base-border-radius;
  .alert {
    border-top-right-radius: $base-border-radius;
    border-top-left-radius: $base-border-radius;
  }
  &.is-left {
    margin-right: 7.5px;
  }
  &.is-right {
    margin-left: 7.5px;
  }
}

.section-transparent-bg {
  background-color: transparent;
}

.section-title {
  display: block;
  padding: 15px 15px 14px;
  border-bottom: 1px solid $base-border-color;
  font-size: 15px;
  font-weight: 600;
  height: 50px;
  i.title-icon,
  img.title-icon {
    line-height: 0.9;
    display: inline-block;
    vertical-align: middle;
    font-size: 20px;
  }
  img.title-icon {
    width: 18px;
    height: 18px;
    margin-top: -4px;
  }
  &.is-outside {
    padding: 15px 0 14px;
    margin: 0 15px;
    background: $base-border-color;
  }
}

.section-actions {
  position: absolute;
  top: 10px;
  right: 15px;
  text-align: right;
  .action {
    display: inline-block;
    margin-left: 15px;
    vertical-align: middle;
  }
}

.section-content {
  display: block;
  padding: 15px;
  &.without-padding {
    padding: 0;
  }
  &.has-table {
    padding: 0;
    th,
    td {
      border-left: 0;
      border-right: 0;
      border-radius: 0 !important;
    }
  }
  &.has-filter {
    section {
      padding: 0;
      margin: 0;
      border-bottom: 1px solid $base-border-color;
    }
  }
}

.section-footer {
  padding: 10px 15px 17px;
  background: $gray-lightest;
  border-top: 1px solid $base-border-color;
  border-bottom-right-radius: $base-border-radius;
  border-bottom-left-radius: $base-border-radius;
}

.section-title-div {
  display: flex;
  min-height: 45px;
  justify-content: space-between;
  align-items: center;
  margin: 0 $main-padding 0;
  background: $gray-lighter;

  h4 {
    padding: 5px 0;
  }

  &.is-sticky {
    z-index: 2;
    top: 0;
    padding-top: $main-padding;
    padding-bottom: $main-padding;
    position: sticky;
  }

  &.is-left {
    margin-right: $main-padding / 2;
  }
  &.is-right {
    margin-left: $main-padding / 2;
  }
}

// Responsive
// =============================================================================

@include breakpoint(medium) {
  section {
    &.is-left {
      margin-right: 20px;
    }
    &.is-right {
      margin-left: 20px;
    }
  }
}
