// =============================================================================
// Values
// =============================================================================

.value-waiting-pill-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    font-size: $base-font-size;
    margin-left: 3px;
  }
}

.value-waiting {
  display: inline-block;
  span {
    font-size: 25px;
    line-height: 0;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-name: blink;
    animation-fill-mode: both;
  }
  span:nth-child(2) {
    animation-delay: 0.2s;
  }
  span:nth-child(3) {
    animation-delay: 0.4s;
  }

  &.pill {
    margin: 0;
    padding: 0;
    height: 19px;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      margin-left: 2px;
      line-height: inherit;
      font-size: $base-font-size;
    }
  }
}
