// =============================================================================
// Drawer
// =============================================================================

.drawer {
  position: fixed;
  top: 121px;
  right: -300px;
  bottom: 0;
  z-index: 150;
  width: 300px;
  overflow: auto;
  background-color: $white;
  border-left: 1px solid $base-border-color;
  transition: right $base-transition-speed ease-in-out;
  &.is-open {
    right: 0;
  }
}

.drawer-open {
  padding-right: 300px;
}
