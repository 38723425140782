// =============================================================================
// Pills
// =============================================================================

@mixin pill-generator($background, $font) {
  color: $font;
  background: $background;
}

.pill {
  display: inline-block;
  padding: 1px 7.5px;
  font-size: 10px;
  font-weight: 600;
  border-radius: $base-border-radius;
  &.uppercase {
    text-transform: uppercase;
  }
}

// Descendant
// =============================================================================

.pill-default {
  @include pill-generator($gray-lightest, $base-font-color);
}
.pill-success {
  @include pill-generator($brand-success, $white);
}
.pill-danger {
  @include pill-generator($brand-danger, $white);
}
.pill-primary {
  @include pill-generator($brand-primary, $white);
}
.pill-alternative {
  @include pill-generator($brand-alternative, $white);
}
.pill-warning {
  @include pill-generator($brand-warning, $white);
}
