// =============================================================================
// Reports
// =============================================================================

.report {
  > h2 {
    padding: 20px 20px 0;
    font-size: 20px;
    font-weight: 500;
    color: $brand-primary;
  }
}
