// =============================================================================
// Tiles
// =============================================================================

.tile {
  position: relative;
  display: flex;
  align-content: center;
  flex: auto;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  padding: 15px 0 15px 15px;
  cursor: pointer;
  border-bottom: 1px solid $base-border-color;
  transition: background $base-transition-speed linear;
  .avatar {
    align-self: center;
  }
  &:hover {
    background: $gray-lightest;
  }
}

.tile-content {
  align-self: center;
  padding-left: 20px;
  i {
    display: block;
    margin-top: 5px;
    font-size: 12px;
    font-weight: 500;
    color: $gray;
    span {
      margin: 0 5px;
    }
    .material-icons {
      display: inline-block;
      margin-top: -2px;
      margin-right: 5px;
      font-size: 14px;
      line-height: 1;
      vertical-align: middle;
    }
  }
}

.tile-danger i {
  color: $brand-danger;
}
