// =============================================================================
// Grid
// =============================================================================

// Settings
// =============================================================================

// Creates widths for each column

@mixin column-generator() {
  @for $i from 1 through ($columns - 1) {
    &.with-#{$i}col {
      width: $one-column * $i;
    }
  }
}

@mixin column-generator-responsive() {
  @for $i from 1 through ($columns - 1) {
    &.with-#{$i}col {
      width: 100%;
    }
  }
}

// Component
// =============================================================================

.grid {
  display: block;
  padding: 0;
  margin: 0 $negative-gutter;
  font-size: 0;
  text-align: left;
}

// Descendants
// =============================================================================

.grid-cell {
  @include column-generator();
  display: inline-block;
  width: 100%;
  padding: 0;
  margin: 0;
  font-size: $base-font-size;
  text-align: left;
  vertical-align: top;
  &.is-center {
    text-align: center;
  }
  &.is-right {
    text-align: right;
  }
}

@include breakpoint(medium) {
  .grid-cell {
    @include column-generator-responsive();
  }
}

// Modifiers
// =============================================================================

.grid-centered {
  max-width: $max-width;
  margin-right: auto;
  margin-left: auto;
}
