// =============================================================================
// Cards
// =============================================================================

.cards-toggle > section:first-child:hover {
  cursor: pointer;
}

.cards-toggle {
  @for $i from 1 through ($columns - 1) {
    &.inset-#{$i} {
      margin-left: $main-padding * $i;
    }
  }
}

.cards {
  display: grid;
  grid-gap: $gutter;
  margin: 0 $main-padding $main-padding;
  grid-template-columns: repeat(auto-fill, minmax(0px, 1fr));
}

.card {
  background: $white;
  border-radius: $base-border-radius;
  transition: $base-transition-speed all;
  border: $base-border-radius solid transparent;
  cursor: pointer;
}

.card-header,
.card-content,
.card-footer {
  padding: $main-padding $main-padding $main-padding;
  height: 100%;
  overflow: auto;
  color: $base-font-color;
}

.card-header,
.card-content {
  border-bottom: 1px solid #eaecee;
}
