// =============================================================================
// Dynamic Filter Section
// =============================================================================

.dynamic-filter-section {
  display: flex;
  padding: 10px;
}

.dynamic-filter-section--filters {
  flex: 1;
}

.dynamic-filter-section--actions {
  flex: none;
}

// =============================================================================
// Dynamic Filter
// =============================================================================

.dynamic-filter {
  position: relative;
  display: inline-block;
}

.dynamic-filter--toggle {
  display: block;
  position: relative;
}

.dynamic-filter--content {
  display: none;
  background: $gray-lighter;
  border-radius: 2px;
  margin-top: 6px;
  min-width: 600px;
  max-height: 800px;
  box-shadow: 0 4px 8px -2px rgba(9, 30, 66, 0.25),
    0 0 1px rgba(9, 30, 66, 0.31);
  box-sizing: border-box;
  position: absolute;
  z-index: 10;
  overflow: visible;

  &.is-active {
    display: block;
  }

  &.is-reverse {
    right: 0;
  }

  &.is-scrolling {
    overflow: auto;
  }

  h6 {
    font-size: 12px;
    margin-bottom: 10px;
  }

  section {
    margin: 10px;
    background: $gray-lighter;
  }
}

.dynamic-filter--form {
  position: relative;
  clear: both;
}

.dynamic-filter--controls {
  text-align: right;
}

// =============================================================================
// Dynamic Filter Button overrides
// =============================================================================

.button-dynamic-filter.is-active,
.button-dynamic-filter:hover + .button-dynamic-filter--remove {
  display: block;
}

.button-dynamic-filter.is-active {
  .drop-down {
    transform: rotate(180deg);
  }
}

.button-dynamic-filter--remove {
  display: none;
  padding: 0;
  border-radius: 50%;
  position: absolute;
  top: -7px;
  right: -7px;
  width: 16px;
  height: 16px;
  z-index: 400;

  > i {
    margin: 0;
    font-size: 11px;
  }

  &:hover,
  &.active {
    display: block;
  }
}

.button-dynamic-filter--remove:hover + .button-dynamic-filter {
  background: $gray-lighter !important;
}
