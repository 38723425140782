// =============================================================================
// Actions
// =============================================================================

// Settings
// =============================================================================

// Scaffolding action buttons with a given color.
// @param {Hex} $background Color for the action type.
// @param {Hex} $font Color for the action type.

@mixin action-generator($background, $font) {
  background: $background;
  i {
    color: $font;
  }
  color: $font;
  &:hover {
    background: lighten($background, 5);
  }
  &:focus,
  &:active {
    background: darken($background, 10);
  }
}

// Component
// =============================================================================

.action {
  position: relative;
  display: inline-block;
  width: 30px;
  // If you adjust the width, please also adjust in src/components/RecordActions.js
  height: 30px;
  padding-top: 7px;
  margin-left: 10px;
  // If you adjust the margin, please also adjust in src/components/RecordActions.js
  text-align: center;
  vertical-align: middle;
  background: $gray-lightest;
  border: 0;
  border-radius: 50%;
  transition: all $base-transition-speed linear;
  i {
    position: absolute;
    font-size: 16px;
    color: $gray;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &:hover {
    background: $gray-lighter;
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    background: $gray-lighter !important;
  }
  &.is-spinning {
    animation: reverse spin 0.8s linear infinite;
  }
}

// Descendant
// =============================================================================

.action-primary {
  @include action-generator($brand-primary, $white);
}

.action-success {
  @include action-generator($brand-success, $white);
}

.action-warning {
  @include action-generator($brand-danger, $white);
}

.action-danger:hover {
  background: $brand-danger;
  i {
    color: lighten($brand-danger, 28);
  }
}
