// =============================================================================
// Keyframes
// =============================================================================

// Mimics a heartbeat.

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}

// Scales an object larger.

@keyframes grow {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.2);
  }
}

// Spins an object

@keyframes spin {
  to {
    transform: rotate(1turn);
  }
}

// Shakes an object

@keyframes shake {
  0% {
    transform: translateX(0);
  }

  20% {
    transform: translateX(-1px);
  }

  40% {
    transform: translateX(1px);
  }

  60% {
    transform: translateX(-1px);
  }

  80% {
    transform: translateX(1px);
  }

  100% {
    transform: translateX(0);
  }
}

// Blinks an object

@keyframes blink {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
