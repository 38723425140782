// =============================================================================
// Popovers
// =============================================================================

.Popover {
  z-index: 100;
  width: 100%;
  max-width: 375px;
}

.Popover-body {
  width: 100%;
  padding: 7.5px 20px;
  font-size: 12px;
  font-weight: 600;
  color: $white;
  background: $gray-darkest;
  border-radius: $base-border-radius;
  dt,
  dd {
    width: 47.5%;
    margin-top: 3px;
  }

  .description-horizontal {
    &.short-dt {
      dt {
        width: 35%;
      }
      dd {
        width: 60%;
      }
    }
  }
}

.Popover-tipShape {
  fill: $gray-darkest;
}

.popover-icon {
  display: inline-block;
  margin-top: -2px;
  color: $gray;
  vertical-align: middle;
}

.overflow-with-popover {
  white-space: nowrap;
  overflow: hidden;

  &.is-overflown {
    text-overflow: ellipsis;
    cursor: pointer;
    text-decoration: underline;
    text-decoration-style: dotted;
  }
}
