// =============================================================================
// Banners
// =============================================================================

// Settings
// =============================================================================

// Scaffolding buttons with a given color.
// @param {Hex} $color Color for the button background.

@mixin banner-generator($color) {
  background: rgba($color, 0.2);
  color: $color;
}

.banner {
  position: absolute;
  top: 0px;
  left: 0px;
  margin: 0 auto;
  text-align: center;
  width: 100%;
}

// Descendant
// =============================================================================

.banner-success {
  @include banner-generator($brand-success);
}
.banner-danger {
  @include banner-generator($brand-danger);
}
.banner-warning {
  @include banner-generator($brand-warning);
}
.banner-primary {
  @include banner-generator($brand-primary);
}
