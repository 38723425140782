// =============================================================================
// Progress
// =============================================================================

.progress {
  position: relative;
  display: block;
  height: 6px;
  background: $gray-lighter;
  border-radius: 1px;
}

.progress-fill {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background: $brand-primary;
  border-radius: 1px;
  transition: max-width $base-transition-speed linear;
}

.progress-detail {
  position: absolute;
  top: -16px;
  right: -25px;
  width: 50px;
  font-size: 10px;
  color: $brand-primary;
  text-align: center;
  text-transform: none;
}

.progress-goal {
  display: block;
  padding-top: 8px;
  font-size: 10px;
  color: $gray;
  text-align: center;
  text-transform: none;
}
