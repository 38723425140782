// =============================================================================
// Tags
// =============================================================================

strong > .tag {
  font-weight: bold;
}

.tag {
  color: $white;
  font-size: 11px;
  padding: 2px 5px;
  margin-right: 5px;
  background-color: $brand-primary;
  border-radius: $base-border-radius;

  * {
    font-size: 11px;
  }
}

.tag-danger {
  @extend .tag;

  background-color: $brand-danger;
}

.tag-success {
  @extend .tag;

  background-color: $brand-success;
}
