// =============================================================================
// Variables
// =============================================================================

// Typography
// =============================================================================

$sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
  sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
$serif: Courier, serif;
$base-font-family: $sans-serif;
$base-font-weight: 300;
$base-font-weight-bold: 700;

// Colors
// =============================================================================

// Shades of gray

$black: #000;
$gray-darkest: #23303f;
$gray-darker: #303846;
$gray-dark: #51607d;
$gray: #a3afc4;
$gray-light: #d3d9e7;
$gray-lighter: #eaecee;
$gray-lightest: #f8fafb;
$white: #fff;

// Colors

$brand-success: #5ac3c3;
$brand-danger: #f76765;
$brand-primary: #005da8;
$brand-warning: #ffb363;
$brand-alternative: #6b6c6e;

// Custom colors

$input-higlight: #ffffdd;

// Font color

$base-font-color: $gray-dark;
$base-accent-color: $brand-primary;
$base-accent-highlighted-color: darken($base-accent-color, 15);

// Border color

$base-border-color: $gray-lighter;

// Sizes
// =============================================================================

// Grid

$columns: 12;
$max-width: 1140px;
$gutter: 15px;
$one-column: 100% / $columns;
$negative-gutter: 0 - $gutter;

// Structure

$sidebar-width: 60px;
$navbar-height: 60px;
$subnav-height: 45px;
$main-padding: 15px;

// Font sizes

$base-font-size: 13px;
$base-line-height: 1.7;

// Box sizes

$base-border-radius: 3px;
$base-box-shadow: 0 2px 0 darken($gray-lighter, 5);
$base-transition-speed: 0.2s;

$min-screen-width: 1070px;

// Z-Indexes

$full-screen-rte-z-index: 500;
