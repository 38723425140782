// =============================================================================
// Lists
// =============================================================================

// Descendants
// =============================================================================

// A list item is the entry inside a list.

.list-item {
  position: relative;
  display: block;
  padding: 20px;
  cursor: pointer;
  background: transparent;
  border-bottom: 1px solid $base-border-color;
  transition: background $base-transition-speed linear;
  &:first-child {
    border-top-right-radius: $base-border-radius;
    border-top-left-radius: $base-border-radius;
  }
  &:last-child {
    border-bottom-right-radius: $base-border-radius;
    border-bottom-left-radius: $base-border-radius;
  }
  &:hover {
    background: $gray-lightest;
  }
  > .pill,
  .button {
    position: absolute;
    top: 50%;
    right: 20px;
    margin-top: -9.5px;
  }
  .button {
    margin-top: -18.5px;
  }
  &.non-clickable {
    cursor: default;
    &:hover {
      background: transparent;
    }
  }
}

// List content is extra information about the list item positioned below the
// list title.

.list-content {
  display: inline-block;
  vertical-align: middle;
  h4 {
    color: $gray-dark;
  }
  p {
    display: block;
    color: $gray;
    text-transform: uppercase;
    img {
      display: inline-block;
      height: 11px;
      margin-right: 20px;
      vertical-align: middle;
    }
    strong {
      display: inline-block;
      margin-right: 20px;
      font-weight: 500;
      vertical-align: middle;
    }
    .pill {
      margin-top: 2px;
      margin-right: 20px;
      vertical-align: middle;
    }
  }
}

// List more is the link on the bottom is the list that can load extra list
// entries.

.list-more {
  display: block;
  width: 100%;
  padding: 20px;
  font-weight: 400;
  color: $gray;
  text-align: center;
  text-transform: uppercase;
  background: transparent;
  border: 0;
  transition: color $base-transition-speed linear;
  i {
    display: inline-block;
    margin: -3px 5px 0;
    font-size: 12px;
    vertical-align: middle;
  }
  &:hover {
    color: $gray-dark;
    background: transparent;
  }
  &:focus {
    outline: none;
  }
}

// List item in primary color

.list-primary {
  padding: 10px 20px;
  border-color: lighten($brand-primary, 4);
  &:first-child,
  &:last-child {
    border-radius: 0;
  }
  .list-content {
    h4 {
      color: $white;
    }
    p {
      color: $gray;
      strong.is-colored {
        font-size: 11px;
        font-weight: 600;
        color: $brand-success;
      }
    }
  }
  &:hover {
    background: lighten($brand-primary, 4);
  }
}
