// =============================================================================
// Buttons
// =============================================================================

// Settings
// =============================================================================

// Scaffolding buttons with a given color.
// @param {Hex} $background Color for the button background.
// @param {Hex} $font Color for the button type.

@mixin button-generator($background, $font) {
  color: $font;
  background: $background;
  &:hover {
    color: $font;
    background: lighten($background, 5);
  }
  &:focus,
  &:active {
    color: $font;
    background: darken($background, 10);
  }
}

// Component
// =============================================================================

.button {
  position: relative;
  display: inline-block;
  padding: $main-padding;
  font-size: 10px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0.1em;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  background: $gray-lighter;
  border: 0;
  border-radius: $base-border-radius;
  transition: all $base-transition-speed ease-in-out;
  appearance: none;
  user-select: none;
  i {
    display: inline-block;
    margin-right: 10px;
    font-size: 16px;
    vertical-align: middle;
  }
  span {
    display: inline-block;
    vertical-align: middle;
  }
  &:hover {
    background: lighten($gray-lighter, 5);
  }
  &:focus,
  &:active {
    background: darken($gray-lightest, 5);
    outline: none;
  }
  &:disabled,
  &.is-disabled {
    color: $gray-lightest;
    cursor: not-allowed;
    background: $gray;
    &:hover,
    &:focus,
    &:active {
      color: $gray-lightest;
      background: $gray;
      outline: none;
    }
  }
}

// Descendants
// =============================================================================

.button-default {
  @include button-generator($white, $brand-primary);
}

.button-gray {
  @include button-generator($gray, $gray-lightest);
}

.button-success {
  @include button-generator($brand-success, $white);
}

.button-danger {
  @include button-generator($brand-danger, $white);
}

.button-primary {
  @include button-generator($brand-primary, $white);
}

.button-alternative {
  @include button-generator($brand-alternative, $white);
}

.button-small {
  padding: 8px 25px 9px;
}

.button-rounded {
  @include button-generator($white, $brand-primary);
  height: 30px;
  padding: 9px 20px;
  border: 1px solid $brand-primary;
  border-radius: 20px;
  &:hover,
  &:active,
  &:focus {
    color: darken($brand-primary, 10);
    border-color: darken($brand-primary, 10);
  }
}

.button-link {
  display: inline-block;
  background: transparent;
  color: $base-accent-color;
  text-transform: unset;
  letter-spacing: 0;
  padding: 12px 0 14px;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.7;
  margin: 0 5px;
  padding: 0;
  border: none;

  &:hover {
    background: transparent;
  }
  &:focus,
  &:active {
    background: transparent;
    outline: none;
  }
  &:disabled,
  &.is-disabled {
    color: $gray;
    cursor: not-allowed;
    background: transparent;
    &:hover,
    &:focus,
    &:active {
      color: $base-accent-color;
      background: transparent;
      outline: none;
    }
  }
}

.button-action {
  @include button-generator($white, $brand-primary);

  width: 100%;
  display: flex;
  text-align: left;
  padding: 12px;
  border-radius: 0;
  border-bottom: 1px solid $gray-lighter;
  line-height: 16px;

  span {
    flex: 1;
    line-height: 16px;
  }

  i {
    margin: 0;
  }

  &:first-of-type {
    border-top-left-radius: $base-border-radius;
    border-top-right-radius: $base-border-radius;
  }

  &:last-of-type {
    border-bottom-left-radius: $base-border-radius;
    border-bottom-right-radius: $base-border-radius;
    border-bottom: none;
  }
}

.button-dynamic-filter {
  @include button-generator($white, $base-font-color);
  height: 30px;
  padding: 7px;
  position: relative;

  span {
    line-height: 16px;
    height: 16px;
    margin-left: 8px;
  }

  i {
    margin-right: 0;
  }

  &:active,
  &:focus {
    color: $base-font-color;
    border-color: $white;
    background: $white;
  }

  &:hover {
    color: $base-font-color;
    border-color: $gray-lighter;
    background: $gray-lighter;
  }

  &.is-active {
    color: $white;
    border-color: $brand-primary;
    background: $brand-primary;
  }
}

.button-block {
  display: block;
  width: 100%;
}

.button-close {
  padding: 8px;
  height: 32px;
  width: 32px;

  i {
    margin: 0;
  }
}
