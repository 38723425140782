// =============================================================================
// Alerts
// =============================================================================

// Settings
// =============================================================================

// Scaffolding buttons with a given color.
// @param {Hex} $color Color for the button background.

@mixin alert-generator($color) {
  background: rgba($color, 0.2);
  i,
  ul li {
    color: $color;
  }
}

// Component
// =============================================================================

.alert {
  position: relative;
  display: grid;
  grid-template-columns: 20px 1fr 20px;
  padding: 20px;
  border-radius: $base-border-radius;
  i {
    display: inline-block;
    font-size: 20px;
    vertical-align: middle;
  }
  ul {
    display: inline-block;
    padding: 0;
    margin: 0 0 0 15px;
    vertical-align: middle;
    list-style: none;
    li {
      font-weight: 500;
    }
  }
}

.alert-close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 20px;
  height: 20px;
  border: none;
  outline: none;
  padding: 0;
  background: transparent;

  i {
    position: absolute;
    transform: translate(-50%, -50%);
  }
}

// Descendant
// =============================================================================

.alert-success {
  @include alert-generator($brand-success);
}
.alert-danger {
  @include alert-generator($brand-danger);
}
.alert-warning {
  @include alert-generator($brand-warning);
}
.alert-primary {
  @include alert-generator($brand-primary);
}
