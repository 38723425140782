// =============================================================================
// Login
// =============================================================================

.signin {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  max-width: 400px;
  text-align: center;

  img {
    align-self: center;
  }

  section,
  .alert {
    margin: 0px;
  }

  .alert {
    padding: 12px;
    margin-bottom: 15px;
  }

  .signin-heading {
    font-size: 1.75rem;
    margin: 25px 0;
  }

  button,
  p {
    margin-bottom: 15px;
  }

  form,
  .login-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    input:first-of-type {
      margin-bottom: -1px;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    input:last-of-type {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      margin-bottom: 15px;
    }

    input:only-of-type {
      border-radius: 1px;
    }

    .form-control {
      position: relative;
      box-sizing: border-box;
      height: auto;
      width: 100%;
      padding: 10px;

      &:focus {
        z-index: 2;
      }
    }
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0;
}
