@media print {
  nav,
  aside,
  .navbar,
  .sidebar,
  .avatar {
    display: none;
  }

  .subnav-links,
  button {
    display: none !important;
  }

  section {
    padding: 0 !important;
    margin: 0 !important;
  }

  h1,
  h2,
  h3,
  h4,
  .subnav-main,
  .section-content {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .wrapper {
    display: block;
    height: auto;
  }

  main {
    margin-top: 0 !important;
    padding-top: 0 !important;
    background: unset;
    transition: unset;
    overflow: visible;
  }

  section.is-left,
  section.is-right {
    margin: 0 !important;
    padding: 0 !important;
  }

  .subnav {
    position: unset;
    top: unset;
    right: unset;
    left: unset;
    z-index: unset;
    display: block;
    background: $white;
    margin: 0 !important;
    padding: 0 !important;
  }

  .subnav-details > * {
    margin-right: 10px !important;
    font-size: 15px !important;
    font-weight: $base-font-weight !important;

    &strong {
      font-weight: 600 !important;
    }
  }

  .section-title,
  .subnav-details {
    display: block;
    margin: 0 !important;
    min-height: unset;
    height: unset;
    padding: 10px 0 9px 0 !important;
  }

  .subnav-details {
    pointer-events: unset;
    border-bottom: none;
    padding: 10px 0 0 0 !important;

    &::after {
      content: attr(title);
      position: absolute;
      right: 0;
      top: 10;
      font-size: 15px !important;
      font-weight: 600 !important;
    }
  }

  .grid-cell {
    display: block;
    width: 100% !important;
    padding: 0;
    margin: 0;
    font-size: unset;
    text-align: unset;
  }

  div {
    text-decoration: none !important;
  }

  .print {
    &.print-hide {
      display: none;
    }
    &.chart-container {
      height: 600px;
    }
    &.print-page-break {
      break-before: always;
    }
  }
}
