.button-group {
  .button {
    border-radius: 0;

    &:first-child {
      border-bottom-left-radius: $base-border-radius;
      border-top-left-radius: $base-border-radius;
    }

    &:last-child {
      border-top-right-radius: $base-border-radius;
      border-bottom-right-radius: $base-border-radius;
    }
  }
}
